/* Base */

* {
	outline: none;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
}

::-webkit-input-placeholder {color: $base_form_placeholder;}
::-moz-placeholder		{color: $base_form_placeholder;}
:-moz-placeholder		{color: $base_form_placeholder;}
:-ms-input-placeholder	{color: $base_form_placeholder;}

html, body {
	margin: 0;
	padding: 0;
	background: $base_background;
	font-size: $base_font_size;
	color: $base_text_color;
	font-family: $base_font_family, sans-serif;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
}

a {
	color: $base_link_color;
	text-decoration: none;
	cursor: pointer;
	&:link {
		color: $base_link_color;
	}
	&:active {
		color: $base_link_color;
	}
	&:visited {
		color: $base_link_color;
	}
	&:hover {
		text-decoration: underline; 
	}
	img {
		border: none;
	}
}

input, textarea, button {
	font-family: $base_font_family;
	-webkit-appearance: none;
	box-shadow: none;
}

label {
	cursor: pointer;
}

.clear {
	clear: both;
}

strong {
	font-weight: 700;
}

.centrize {
	display: table !important;
	table-layout: fixed !important;
	height: 100% !important;
	position: relative !important;
	&.full-width {
		width: 100% !important;
	}
}

.vertical-center {
	display: table-cell !important;
	vertical-align: middle !important;
}

.slimScrollRail {
	width: 2px!important;
	display: block!important;
	background: transparent!important;
	opacity: 1!important;
	right: 0!important;
}

.slimScrollBar {
	width: 4px!important;
	background: #D8D8D8!important;
	border: none!important;
	cursor: pointer!important;
	opacity: 1!important;
	right: 0!important;
	@include border-radius(4px);
}

/*Buttons*/

a.btn, .btn {
	padding: 0 50px;
	position: relative;
	display: inline-block;
	vertical-align: top;
	height: 60px;
	line-height: 56px;
	font-size: 16px;
	color: $base_white_color;
	text-align: center;
	text-decoration: none;
	font-weight: 400;
	background: #FAD961;
	background: -moz-linear-gradient(top, #FAD961 0%, #F76B1C 100%);
	background: -webkit-linear-gradient(top, #FAD961 0%,#F76B1C 100%);
	background: linear-gradient(to bottom, #FAD961 0%,#F76B1C 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FAD961', endColorstr='#F76B1C',GradientType=0 );
	border: none;
	border-bottom: 4px solid #9E6508;
	cursor: pointer;
	@include border-radius(4px);
	@include transition(all 0.3s ease 0s);
	@include box-shadow(0 20px 50px rgba(105,67,4,0.5));
	&.border {
		background: none;
		border: 3px solid #F9A540;
		@include border-radius(4px);
		@include box-shadow(0 0 0 rgba(105,67,4,0.5));
	}
	&:hover {
		@include box-shadow(0 0 0 rgba(105,67,4,0.5));
	}
}

/*Wrapper*/

.bg {
	position: relative;
	overflow: hidden;
}

.fw {
	position: relative;
	margin: 0 auto;
	width: $base_width;
	&:after {
		content: '';
		clear: both;
		display: block;
	}
}

.disabled {display: none;}

/*Titles*/

.m-title {
	position: relative;
	margin: 0 0 50px 0;
	font-size: 50px;
	line-height: 60px;
	font-weight: 800;
	color: $base_text_color;
	text-align: center;
}

.m-subtitle {
	font-size: 18px;
	line-height: 30px;
	color: $base_text_color;
}

/*Preloader*/

.preloader {
	position: fixed;
	top:0;
	left:0;
	width: 100%;
	height: 100%;
	text-align: center;
	background: $base_white_color;
	z-index: 1000;
	.spinner {
		width: 64px;
		height: 64px;
		display: inline-block;
		position: relative;
		background: url(../images/preloader.gif) no-repeat;
		background-size: 100% 100%;
	}
}

/*Forms*/

.c-form {
	.group {
		margin: 0 0 15px 0;
	}
	.field {
		position: relative;
		input[type="text"], input[type="tel"], input[type="password"], input[type="email"], textarea {
			padding: 0 10px;
			width: 100%;
			height: 40px;
			font-size: 16px;
			color: #2C4527;
			font-family: $base_font_family;
			font-weight: 500;
			border: none;
			border-bottom: 1px solid rgba(108,141,102,0.5);
			background: none;
			@include transition(all 0.3s ease 0s);
			&.error {
				border-color: red;
			}
		}
		label.error {
			position: absolute;
			left: 0;
			bottom: -18px;
			font-size: 12px;
			color: red;
			text-align: left;
			color: red;
			width: 100%;
		}
	}
	.info-text {
		margin: 35px 0 0 0;
		position: relative;
		display: block;
		font-size: 14px;
		line-height: 22px;
		color: $base_text_color;
		font-weight: 400;
		a {
			color: #FA0663;
			font-weight: 700;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	.submit-btn {
		margin-top: 35px;
		padding: 0;
		float: none;
		display: block;
		width: 100%;
		cursor: pointer;
	}
}

/*Popups*/

.overlay, .cf-overlay {
	display: none;
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background: #000;
	opacity: 0.6;
	z-index: 101;
}

.nonebox {
	position: absolute;
	left: 50%;
	top: -3000px;
	width: 384px;
	margin: 0 0 0 -192px;
	padding: 45px 40px;
	background: #fff;
	z-index: 1002;
	text-align: left;
	@include border-radius(10px);
	.close {
		position: absolute;
		top: -50px;
		right: -50px;
		width: 50px;
		height: 50px;
		background: url(../images/nclose.png) no-repeat;
		background-size: contain;
		font-size: 0;
		cursor: pointer;
	}
	.title {
		margin: 0 0 20px 0;
		font-family: $base_font_family;
		font-size: 26px;
		line-height: 36px;
		color: $base_text_color;
		font-weight: 700;
	}
	.subtitle {
		font-size: 18px;
		line-height: 28px;
		color: $base_text_color;
	}
}