/*Header*/

.header {
	padding: 35px 0 0 0;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 160px;
	z-index: 90;
	&.opened {
		overflow: auto;
		height: 100%;
		.menu-btn {
			background: none;
			&:before {
				opacity: 0;
			}
			&:after {
				opacity: 1;
			}
		}
	}
	.logo {
		margin-top: -15px;
		float: left;
		width: 339px;
		font-size: 0;
		a {
			text-decoration: none;
		}
		img {
			max-width: 100%;
		}
	}
	.contacts {
		margin-right: 50px;
		float: right;
		font-size: 16px;
		line-height: 19px;
		color: #fff;
		.tel {
			margin-bottom: 4px;
			display: block;
			font-size: 30px;
			line-height: 37px;
			color: #fff;
			font-weight: 800;
			text-decoration: none;
		}
	}
	.head-btn {
		float: right;
		width: 280px;
		height: 60px;
		line-height: 56px;
		font-family: $base_font2_family;
		font-size: 16px;
		color: #fff;
		text-align: center;
		text-decoration: none;
		border: 3px solid #F9A540;
		@include border-radius(60px);
	}
	.menu-btn {
		padding: 0;
		display: none;
		width: 70px;
		height: 55px;
		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: url(../images/menu.png) no-repeat center center;
			background-size: 35px auto;
		}
		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: url(../images/menu2.png) no-repeat center center;
			background-size: 25px auto;
			opacity: 0;
		}
	}
}