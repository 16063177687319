/*Footer*/

.footer {
	padding: 90px 0 0 0;
	background: url(../images/footer.jpg) no-repeat top center;
	background-size: cover;
	.f-left {
		float: left;
		width: 330px;
	}
	.f-right {
		float: right;
		width: 790px;
	}
	.f-bottom {
		margin-top: 70px;
		padding-top: 25px;
		padding-bottom: 25px;
		border-top: 1px solid #141414;
	}
	.m-title {
		margin: 0 0 35px 0;
		color: $base_white_color;
		text-align: left;
	}
	.f-logo {
		margin-right: 125px;
		float: left;
		width: 339px;
		font-size: 0;
		img {
			max-width: 100%;
		}
	}
	.f-pol {
		margin-top: 55px;
		float: left;
		font-size: 16px;
		line-height: 20px;
		color: #fff;
		text-decoration: underline;
	}
	.f-create {
		margin-top: 30px;
		float: right;
		width: 165px;
		font-size: 16px;
		line-height: 20px;
		color: #fff;
		a {
			margin-top: 5px;
			display: block;
			font-weight: 800;
			color: #fff;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}
}

.f-contacts {
	.tel {
		margin-bottom: 10px;
		font-size: 16px;
		line-height: 20px;
		color: #fff;
		font-weight: 500;
		a {
			display: block;
			font-size: 30px;
			line-height: 38px;
			color: #fff;
			font-weight: 800;
			text-decoration: none;
		}
	}
	.time {
		margin-bottom: 30px;
		font-size: 16px;
		line-height: 20px;
		color: #fff;
		font-weight: 400;
	}
	.ins-lnk {
		position: relative;
		margin-bottom: 50px;
		padding: 0 0 0 45px;
		display: inline-block;
		vertical-align: top;
		font-size: 16px;
		line-height: 20px;
		font-weight: 700;
		color: #F90563;
		text-decoration: none;
		&:before {
			margin-top: -15px;
			content: '';
			position: absolute;
			left: 0;
			top: 50%;
			width: 30px;
			height: 30px;
			background: url(../images/ins.png) no-repeat;
		}
		span {
			border-bottom: 2px solid rgba(249,5,99,0.3);
		}
	}
	.btn {
		margin-bottom: 20px;
		width: 300px;
		&.price {
			padding-left: 30px;
			padding-right: 80px;
			&:before {
				content: '';
				position: absolute;
				top: -12px;
				right: -10px;
				width: 97px;
				height: 90px;
				background: url(../images/price_ic.png) no-repeat;
			}
		}
	}
}

.map-box {
	overflow: hidden;
	background: #fff;
	@include border-radius(8px);
}

.map-box, .map-desc, .map-image {
	position: relative;
	height: 470px;
}

.map-desc {
	padding: 45px 30px;
	float: left;
	width: 40%;
	&:before {
		content: '';
		position: absolute;
		right: 0;
		bottom: 0;
		width: 94px;
		height: 143px;
		background: url(../images/flower.png) no-repeat;
	}
	.map-sel {
		margin-bottom: 20px;
		.label {
			position: relative;
			font-size: 14px;
			line-height: 20px;
			color: $base_text_color;
		}
	}
	.map-info {
		position: relative;
		overflow: auto;
		height: 280px;
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			li {
				margin-bottom: 25px;
				font-size: 16px;
				line-height: 26px;
				color: $base_text_color;
				font-weight: 500;
				&:last-child {
					margin-bottom: 0;
				}
				span {
					margin: 0 0 8px 0;
					display: block;
					line-height: 20px;
					color: #CFCFCF;
				}
				a {
					margin-bottom: 8px;
					display: block;
					color: $base_text_color;
					text-decoration: none;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}

.map-image {
	float: right;
	width: 60%;
	.map {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
}