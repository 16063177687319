@media screen and (max-width: 1200px) {
	.fw {width: 720px;}

	/* header */
	.header {padding: 20px 0; height: 100px;}
	.header .logo {margin-top: 0; width: 204px;}
	.header .head-btn {width: 210px;}
	.header .contacts {margin-right: 40px; font-size: 14px;}
	.header .contacts .tel {margin-bottom: 0; font-size: 22px;}

	/*started*/
	.section.m-started {padding-top: 150px;}
	.started-intro {width: 310px;}
	.started-intro:before {display: none;}
	.started-intro .title {font-size: 50px; line-height: 60px;}
	.started-intro .list {margin-bottom: 60px;}
	.started-intro .list ul li {font-size: 18px;}
	.started-intro .ben-items .ben-col {width: 100%;}
	.started-intro .ben-item {padding: 0 0 0 60px; min-height: 60px; font-size: 18px; line-height: 30px;}
	.started-intro .ben-item .icon {width: 40px; height: 50px;}
	.started-form {margin-top: 0; padding: 45px 25px; width: 350px;}
	.started-about {margin-top: 70px;}
	.started-about .desc {margin-bottom: 70px; top: 0; float: none; width: auto;}
	.started-about .image {float: none; width: 100%;}
	.lep-bg {display: none;}

	/*news*/
	.section.m-news {padding: 70px 0;}
	.news-items .news-col {width: 50%;}

	/*professional*/
	.section.m-prof .fw:before {display: none;}
	.section.m-prof .titles {margin-bottom: 50px; float: none; width: auto; text-align: center;}
	.section.m-prof .titles .m-title {text-align: center;}
	.section.m-prof .list {float: none; width: auto;}

	/*partners*/
	.section.m-partners .m-title {text-align: center;}
	.section.m-partners .m-subtitle {text-align: center;}

	/*geo*/
	.section.m-geo {padding: 70px 0 0 0;}
	.section.m-geo .fw {width: auto;}
	.section.m-geo .titles {max-width: 720px;}

	/*pricing*/
	.section.m-pricing {padding: 70px 0; margin-top: 0; height: auto; background: #fff;}
	.section.m-pricing .titles {padding: 0; width: auto; float: none; text-align: center;}

	/*footer*/
	.footer {padding: 70px 0 0 0;}
	.footer .f-left, .footer .f-right {float: none; width: auto;}
	.f-contacts .c-group, .f-contacts .btn-group {float: left; width: 50%;}
	.footer .f-pol {display: none;}
}

@media screen and (max-width: 740px) {
	.fw {width: 300px;}
	a.btn, .btn {padding: 0 25px; display: block; font-size: 14px;}
	.c-form .field input[type="text"], .c-form .field input[type="tel"], .c-form .field input[type="password"], .c-form .field input[type="email"], .c-form .field textarea {font-size: 15px;}
	.m-title {margin: 0 0 30px 0; font-size: 29px; line-height: 39px;}
	.m-subtitle {font-size: 16px; line-height: 26px;}

	/* header */
	.header {padding: 15px 0; height: 80px;}
	.header .logo {margin-top: 0; width: 170px;}
	.header .head-btn {width: 50px; height: 50px; line-height: 46px; font-size: 0; background: url(../images/telr.png) no-repeat center center; background-size: 25px 25px;}
	.header .contacts {margin-right: 23px; padding-top: 13px; font-size: 0;}
	.header .contacts .tel {width: 25px; height: 25px; font-size: 0; background: url(../images/tel.png) no-repeat center center; background-size: 25px 25px; filter: brightness(0) invert(1);}

	/*started*/
	.section.m-started {padding-top: 110px;}
	.started-intro {width: auto; float: none;}
	.started-intro .title {font-size: 29px; line-height: 39px;}
	.started-intro .list {margin-bottom: 50px;}
	.started-intro .list ul li {padding-left: 25px; font-size: 16px; line-height: 35px;}
	.started-intro .list ul li:before {width: 14px; height: 14px; margin-top: -7px;}
	.started-intro .ben-item {padding: 0 0 0 50px; min-height: 40px; font-size: 15px;}
	.started-intro .ben-item .icon {margin-top: -10px; width: 30px; height: 40px;}
	.started-form {margin-top: 25px; padding: 30px 20px; float: none; width: auto;}
	.started-form .title {font-size: 22px; line-height: 32px;}
	.started-about {margin-top: 50px;}
	.started-about .desc {margin-bottom: 50px;}
	.started-about .desc .title {font-size: 29px; line-height: 39px;}
	.started-about .desc .text p {font-size: 15px; line-height: 26px;}
	.started-about .image {float: none; width: 100%;}

	/*news*/
	.section.m-news {padding: 50px 0 60px 0;}
	.section.m-news .bts {margin-top: 0;}
	.news-items .news-col {width: 100%;}
	.sale-item {min-height: auto;}
	.sale-item .image {height: 400px;}
	.sale-item .desc {padding: 0 20px 30px 20px;}
	.sale-item .desc .name {font-size: 20px;}
	.sale-item .desc .text {font-size: 15px;}
	.news-item {min-height: auto;}
	.news-item .image {margin-bottom: 20px;}
	.news-item .desc {padding: 0 20px 20px 20px;}
	.news-item .desc .text {font-size: 15px;}

	/*professional*/
	.section.m-prof {padding: 50px 0;}
	.section.m-prof .titles {margin-bottom: 30px;}
	.section.m-prof .list {padding: 30px 20px; @include border-radius(15px);}
	.section.m-prof .col {float: none; width: 100%;}
	.section.m-prof .list ul li {margin-bottom: 15px; font-size: 16px;}

	/*partners*/
	.section.m-partners {padding: 50px 0 60px 0;}

	/*geo*/
	.section.m-geo {padding: 50px 0 0 0;}
	.section.m-geo .fw {width: auto;}
	.section.m-geo .titles {max-width: 300px;}
	.geo-popup {margin-left: -125px; padding: 15px; width: 250px;}

	/*pricing*/
	.section.m-pricing {padding: 50px 0 60px 0;}
	.section.m-pricing .title {font-size: 29px; line-height: 39px; text-align: left;}
	.section.m-pricing .subtitle {font-size: 16px; line-height: 26px;}

	/*footer*/
	.footer {padding: 50px 0 0 0;}
	.f-contacts .c-group, .f-contacts .btn-group {float: none; width: 100%;}
	.f-contacts .tel a {font-size: 24px;}
	.footer .f-right {margin-top: 50px;}
	.map-box {height: auto;}
	.map-desc {padding: 30px 20px; float: none; width: 100%; height: auto;}
	.map-desc .map-info ul li:last-child {margin-bottom: 0;}
	.map-image {float: none; width: 100%;}
	.footer .f-logo {margin-right: 0; float: none; width: 100%;}
	.footer .f-create {margin-top: 30px; float: none; font-size: 16px;}

	.nonebox {width: 300px; margin: 0 0 0 -150px; padding: 30px 20px;}
	.nonebox .title {font-size: 20px; line-height: 30px;}
	.nonebox .close {top: -60px; right: 0;}
}