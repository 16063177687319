/* Home */

.section {
	position: relative;
}

/* Section Started */

.section.m-started {
	padding-top: 170px;
	padding-bottom: 70px;
	position: relative;
	min-height: 1460px;
	background: url(../images/started_bg.jpg) no-repeat top center;
	background-size: cover;
}

.started-intro {
	position: relative;
	float: left;
	width: 610px;
	&:before {
		content: '';
		position: absolute;
		right: -325px;
		top: 15px;
		width: 452px;
		height: 468px;
		background: url(../images/mag.png) no-repeat;
		background-size: contain;
	}
	.title {
		margin: 0 0 20px 0;
		font-size: 60px;
		line-height: 70px;
		font-weight: 800;
		color: #fff;
	}
	.list {
		margin-bottom: 115px;
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			li {
				position: relative;
				padding: 0 0 0 35px;
				font-size: 20px;
				line-height: 45px;
				font-weight: 500;
				color: #fff;
				&:before {
					margin-top: -9px;
					content: '';
					position: absolute;
					left: 0;
					top: 50%;
					width: 18px;
					height: 18px;
					background: #fff;
					@include border-radius(18px);
					@include box-shadow(0 8px 23px rgba(255,255,255,0.43));
				}
			}
		}
	}
	.ben-items {
		margin: 0 -12px;
		font-size: 0;
		.ben-col {
			padding: 0 12px 24px 12px;
			display: inline-block;
			vertical-align: top;
			width: 33.333%;
		}
	}
	.ben-item {
		position: relative;
		padding: 80px 0 0 0;
		font-size: 18px;
		line-height: 28px;
		color: #fff;
		font-weight: 400;
		.icon {
			position: absolute;
			left: 0;
			top: 0;
			width: 60px;
			height: 60px;
			background-size: contain;
			background-position: center center;
			background-repeat: no-repeat;
		}
	}
}

.started-form {
	position: relative;
	margin-top: -10px;
	padding: 45px 40px;
	float: right;
	width: 384px;
	background: #fff;
	z-index: 2;
	@include border-radius(10px);
	.title {
		margin-bottom: 20px;
		font-size: 26px;
		line-height: 36px;
		color: $base_text_color;
		font-weight: 700;
	}
}

.started-about {
	margin-top: 130px;
	.desc {
		position: relative;
		top: -18px;
		float: left;
		width: 486px;
		.title {
			margin: 0 0 20px 0;
			font-size: 50px;
			line-height: 60px;
			color: #fff;
			font-weight: 800;
		}
		.text {
			margin-bottom: 35px;
			p {
				margin: 10px 0;
				font-size: 18px;
				line-height: 30px;
				font-weight: 400;
				color: #fff;
			}
		}
	}
	.image {
		float: right;
		width: 690px;
	}
	.video {
		position: relative;
		width: 100%;
		font-size: 0;
		@include transition(all 0.3s ease 0s);
		@include box-shadow(0 20px 50px rgba(0,0,0,0.5));
		&:hover {
			@include box-shadow(0 0 0 rgba(0,0,0,0.5));
		}
		a {
			&:before {
				margin: -50px 0 0 -50px;
				content: '';
				position: absolute;
				left: 50%;
				top: 50%;
				width: 100px;
				height: 100px;
				background: url(../images/play.svg) no-repeat;
				background-size: contain;
				@include box-shadow(0 10px 25px rgba(0,0,0,0.25));
				z-index: 4;
			}
			&:after {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background: -moz-radial-gradient(center, ellipse cover, rgba(0,0,0,0) 0%, rgba(33,33,3,1) 100%);
				background: -webkit-radial-gradient(center, ellipse cover, rgba(0,0,0,0) 0%,rgba(33,33,33,1) 100%);
				background: radial-gradient(ellipse at center, rgba(0,0,0,0) 0%,rgba(33,33,33,1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#212121',GradientType=1 );
				z-index: 3;
			}
		}
		img {
			width: 100%;
			height: auto;
		}
	}
}

.lep-bg {
	position: absolute;
	&.item-1 {
		left: -580px;
		bottom: 50px;
		width: 474px;
		height: 467px;
		background: url(../images/lep1.png) no-repeat;
		z-index: 2;
	}
	&.item-2 {
		left: -580px;
		bottom: 150px;
		width: 496px;
		height: 512px;
		background: url(../images/lep2.png) no-repeat;
	}
}

/* Section News */

.section.m-news {
	padding: 90px 0;
	.bts {
		margin-top: 25px;
		text-align: center;
	}
}

.news-items {
	margin: 0 -12px;
	font-size: 0;
	.news-col {
		padding: 0 12px 24px 12px;
		display: inline-block;
		vertical-align: top;
		width: 33.333%;
	}
}

.news-item {
	position: relative;
	background: #fff;
	min-height: 470px;
	border: 1px solid #E5E5E5;
	@include transition(all 0.3s ease 0s);
	@include border-radius(4px);
	.image {
		margin-top: -1px;
		margin-bottom: 25px;
		img {
			max-width: 100%;
			@include border-radius(4px);
		}
	}
	.desc {
		padding: 0 30px;
		.name {
			margin-bottom: 10px;
			display: block;
			font-size: 20px;
			line-height: 30px;
			color: $base_text_color;
			font-weight: 700;
			text-decoration: none;
		}
		.text {
			font-size: 16px;
			line-height: 26px;
			color: $base_text_color;
		}
	}
	&:hover {
		@include box-shadow(0 25px 50px rgba(0,0,0,0.25));
	}
}

.sale-item {
	position: relative;
	background: #fff;
	min-height: 470px;
	@include transition(all 0.3s ease 0s);
	a {
		text-decoration: none;
	}
	.image {
		position: relative;
		overflow: hidden;
		display: block;
		width: 100%;
		height: 470px;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		@include border-radius(4px);
		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(33,33,33,1) 100%);
			background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(33,33,33,1) 100%);
			background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(33,33,33,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#212121',GradientType=0 );
		}
	}
	.desc {
		position: absolute;
		left: 0;
		bottom: 0;
		padding: 0 30px 45px 30px;
		width: 100%;
		.name {
			margin-bottom: 10px;
			display: block;
			font-size: 25px;
			line-height: 30px;
			color: $base_white_color;
			font-weight: 800;
			text-decoration: none;
		}
		.text {
			margin-bottom: 20px;
			display: block;
			font-size: 16px;
			line-height: 26px;
			color: $base_white_color;
		}
		.more-btn {
			display: inline-block;
			vertical-align: top;
			font-size: 16px;
			line-height: 20px;
			color: #fff;
			font-weight: 800;
			border-bottom: 2px solid #fff;
		}
	}
	&:hover {
		@include box-shadow(0 25px 50px rgba(0,0,0,0.25));
	}
}

/* Section Professional */

.section.m-prof {
	padding: 65px 0;
	background: url(../images/prof_bg.jpg) no-repeat top center;
	background-size: cover;
	.fw {
		&:before {
			content: '';
			position: absolute;
			left: -465px;
			top: -65px;
			bottom: -65px;
			width: 50%;
			background: url(../images/man.png) no-repeat left top;
			background-size: auto 100%;
		}
	}
	.titles {
		float: left;
		width: 500px;
	}
	.m-title {
		margin: 0 0 20px 0;
		color: $base_white_color;
		text-align: left;
	}
	.m-subtitle {
		color: $base_white_color;
	}
	.list {
		padding: 60px 40px 40px 40px;
		float: right;
		width: 645px;
		background: url(../images/prof_list.png) no-repeat;
		background-size: cover;
		@include border-radius(4px);
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			font-size: 0;
			li {
				position: relative;
				margin-bottom: 20px;
				padding: 0 0 0 15px;
				font-size: 18px;
				line-height: 22px;
				color: $base_text_color;
				font-weight: 500;
				&:before {
					margin-top: -3px;
					content: '';
					position: absolute;
					left: 0;
					top: 50%;
					width: 6px;
					height: 6px;
					background: url(../images/list.svg) no-repeat;
					background-size: contain;
				}
			}
		}
	}
	.col {
		float: left;
		width: 50%;
	}
}

/* Section Partners */

.section.m-partners {
	padding: 65px 0 95px 0;
	.m-title {
		margin: 0 0 10px 0;
		text-align: left;
	}
}

.partners-carousel {
	margin-top: 50px;
	padding: 0 60px;
	.owl-carousel .owl-stage-outer {
		padding-bottom: 0;
	}
	.item {
		a {
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			width: 160px;
			height: 80px;
			font-size: 0;
			img {
				max-width: 100%;
				filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
				filter: gray;
				-webkit-filter: grayscale(100%);
				opacity: 0.3;
				@include transition(all 0.3s ease 0s);
			}
			&:hover {
				img {
					filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
					-webkit-filter: grayscale(0%);
					opacity: 1;
				}
			}
		}
	}
	.owl-prev, .owl-next {
		margin-top: -30px;
		position: absolute;
		top: 50%;
		right: -60px;
		width: 60px;
		height: 60px;
		font-size: 0;
		cursor: pointer;
		@include border-radius(60px);
		@include transition(all 0.3s ease 0s);
		&:before, &:after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: url(../images/nav.svg) no-repeat center center;
			opacity: 1;
			@include transition(all 0.3s ease 0s);
		}
		&:after {
			background: url(../images/nav2.svg) no-repeat center center;
			opacity: 0;
		}
		&:hover {
			@include box-shadow(0 20px 50px rgba(105,67,4,0.5));
			&:before {
				opacity: 0;
			}
			&:after {
				opacity: 1;
			}
		}
	}
	.owl-prev {
		right: auto;
		left: -60px;
		@include transform(scaleX(-1));
	}
}

/* Section Geo */

.section.m-geo {
	padding: 100px 0 0 0;
	height: 900px;
	background: url(../images/geo_bg.jpg) no-repeat top center;
	.titles {
		margin: 0 auto;
		max-width: 590px;
		text-align: center;
	}
	.m-title {
		margin-bottom: 20px;
		color: #fff;
	}
	.m-subtitle {
		margin: 0;
		color: #fff;
	}
}

.geo-map-fw {
	margin-top: -175px;
	padding-top: 175px;
	position: relative;
	overflow: auto;
}

.geo-map {
	position: relative;
	width: 1200px;
	height: 643px;
	background: url(../images/g_map.png) no-repeat top center;
	background-size: contain;
}

.geo-items {
	position: relative;
}

.geo-item {
	position: absolute;
	right: 0;
	top: 0;
	width: 52px;
	height: 52px;
	&.item-1 {
		top: 144px;
		right: 300px;
	}
	&.item-2 {
		top: 302px;
		right: 280px;
	}
	&.item-3 {
		top: 372px;
		right: 230px;
	}
	&.item-4 {
		top: 197px;
		right: 440px;
	}
	&.item-5 {
		top: 142px;
		right: 510px;
	}
	&.item-6 {
		top: 209px;
		right: 497px;
	}
	&.item-7 {
		top: 258px;
		right: 488px;
	}
	&.item-8 {
		top: 354px;
		right: 475px;
	}
	&.item-9 {
		top: 401px;
		right: 497px;
	}
	&.item-10 {
		top: 156px;
		right: 561px;
	}
	&.item-11 {
		top: 177px;
		right: 608px;
	}
	&.item-12 {
		top: 212px;
		right: 562px;
	}
	&.item-13 {
		top: 355px;
		right: 829px;
	}
	&.item-14 {
		top: 384px;
		right: 874px;
	}
	&.item-15 {
		top: 335px;
		right: 895px;
	}
	.geo-btn {
		display: block;
		width: 52px;
		height: 52px;
		cursor: pointer;
		&:before, &:after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: url(../images/geo_ic.svg) no-repeat;
			background-size: contain;
			opacity: 1;
			
		}
		&:after {
			background: url(../images/geo_ic2.svg) no-repeat;
			background-size: contain;
			opacity: 0;
		}
		&.active {
			&:before {
				opacity: 0;
			}
			&:after {
				opacity: 1;
			}
		}
	}
}

.geo-popup {
	display: none;
	margin-left: -158px;
	position: absolute;
	left: 50%;
	bottom: 70px;
	padding: 20px;
    display: none;
    width: 316px;
    background: #fff;
    border-top: 5px solid #F5A623;
    z-index: 5;
    @include border-radius(6px);
    @include box-shadow(0 20px 40px rgba(53,0,61,0.4));
    &:before {
    	margin-left: -15px;
    	content: '';
    	position: absolute;
    	left: 50%;
    	bottom: -30px;
    	border: 15px solid transparent;
    	border-top: 15px solid #fff;
    }
    .name {
		margin-bottom: 10px;
		font-size: 18px;
		line-height: 22px;
		font-weight: 800;
		color: #000;
    }
    .text {
    	margin-right: 10px;
    	position: relative;
    	overflow: auto;
    	height: 200px;
    	font-size: 14px;
    	line-height: 20px;
    	color: $base_text_color;
    }
}

/* Section Pricing */

.section.m-pricing {
	margin-top: -110px;
	height: 608px;
	background: url(../images/price_bg.png) no-repeat top center;
	.titles {
		padding: 200px 0 0 0;
		width: 590px;
		float: right;
	}
	.title {
		margin: 0 0 20px 0;
		font-size: 35px;
		line-height: 43px;
		font-weight: 700;
		color: $base_text_color;
	}
	.subtitle {
		margin-bottom: 45px;
		font-size: 18px;
		line-height: 30px;
		color: $base_text_color;
	}
}